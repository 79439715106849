import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Observable, from, throwError, BehaviorSubject } from "rxjs";
import { map, filter, catchError, mergeMap, tap, finalize } from 'rxjs/operators';
import { TokenStorage } from "../../core/auth/token-storage.service";
import { Utils } from "../utils";

@Injectable()
export abstract class GenericService<T> {
	loadingSubject = new BehaviorSubject<boolean>(false);
	loading$ = this.loadingSubject.asObservable();
	urlServer: string = environment.URL_SERVER;
	http: HttpClient;
	objectName: string;
	objectList: string;
	constructor(private h: HttpClient, private classs: string) {
		this.http = h;

		this.objectName = classs.substring(0, classs.length - 2);
		this.objectList = this.objectName + "List";
	}

	getAllByField(field: string, value: any, sortField?: string, dSort?: boolean): Observable<T[]> {
		this.loadingSubject.next(true);


		if (sortField != undefined) {
			let sort = "!a";
			if (dSort == undefined || !dSort)
				sort = "!d";

			sortField = "/" + sortField + sort;
		} else {
			sortField = "";
		}

		return this.http.get(environment.URL_SERVER + this.classs + "/" + value + "/" + field + sortField, this.jwt()).pipe(
			map((results: any) => {
				return results.data[this.objectList] as T[];;
			}),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		)

	}

	getAll(): Observable<T[]> {
		this.loadingSubject.next(true);
		return this.http.get(environment.URL_SERVER + this.classs, this.jwt()).pipe(
			map((results: any) => {
				
				return results.data[this.objectList] as T[];
			}),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		)

	}

	getDataTable(obj): Observable<any> {
		this.loadingSubject.next(true);
		return this.http.post(environment.URL_SERVER + this.classs+"/dataTable",obj, this.jwt()).pipe(
			map((results: any) => {
				return results.data;
			}),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		)

	}

	getById(id: any): Observable<T> {
		this.loadingSubject.next(true);
		return this.http.get(environment.URL_SERVER + this.classs + "/" + id, this.jwt()).pipe(
			map((results: any) => {
				return results.data[this.objectName] as T;
			}),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		);

	}

	create(obj: T): Observable<T> {
		this.loadingSubject.next(true);
		return this.http.post(environment.URL_SERVER + this.classs + "/", obj, this.jwt()).pipe(
			map((results: any) => {
				return results.data[this.objectName] as T;
			}),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		);

	}

	update(id: any, obj: T): Observable<T> {
		this.loadingSubject.next(true);
		return this.http.put(environment.URL_SERVER + this.classs + "/" + id, obj, this.jwt()).pipe(
			map((results: any) => <T>results.data[this.objectName]),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		)

	}

	delete(id: any): Observable<T> {
		this.loadingSubject.next(true);
		return this.http.delete(environment.URL_SERVER + this.classs + "/" + id, this.jwt()).pipe(
			map((results: any) => {
				return results;
			}),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		)

	}

	downloadFile(id: string): Observable<any> {

		// Utils.log(environment.URL_SERVER + this.classs + "/" + id);
		return this.http.get(environment.URL_SERVER + this.classs + "/" + id, {
			headers: {
				'Authorization': 'Bearer ' + this.getToken()
			},
			responseType: 'arraybuffer'
		});

	}

	getPeso(): Observable<any> {
		return this.http.get(environment.URL_SERVER +  "pesoBilancia", this.jwt()).pipe(
			map((results: any) => {
				Utils.log(results);
				return results.data.peso;
			}),
			catchError(this.handleError),
			finalize(() => this.loadingSubject.next(false))
		);
	}

	


	public jwt() {
		// create authorization header with jwt token
		let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.getToken() });
		headers.append("Content-Type", "application/json");
		return { headers: headers };
	}

	public getToken() {
		// let currentUser = JSON.parse(localStorage.getItem('accessToken'));
		// if (currentUser && currentUser.token) {
		// 	return currentUser.token;
		// } else
		// 	return "";
		return localStorage.getItem('accessToken');
		// return TokenStorage.getAccessToken()
	}


	handleError(error: HttpErrorResponse) {

		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
			return throwError({ response: 1000, errors: error.error.message });

		} else {
			console.error('An error occurred:', error);
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,

			const OK = 0;
			const ERROR_REQUIRED_FIELDS = 1;
			const ERROR_INSERT_DB = 2;
			const ERROR_ID_NOT_VALID = 3;
			const ERROR_ACTION_PARAM_NOT_VALID = 4;
			const ERROR_DELETE_DB = 5;
			const ERROR_UPDATE_DB = 6;
			const ERROR_DUPLICATE_ENTRY = 7;
			const ERROR_SESSION_NOT_VALID = 8;
			const ERROR_PASSWORD_DONT_MACTH = 9;
			const ERROR_EMAIL_NOT_SENT = 10;
			const ERROR_PURCHASE = 11;
			const ERROR_SIZE_LIMIT_REQUEST = 12;
			const ERROR_DELETE_DB_REFERENCE = 13;
			const ERROR_INVALID_USERNAME_PASSWORD = 14;
			const ERROR_INVALID_SESSION = 15;
			const ERROR_NOT_AUTHORIZED = 16;
			const ERROR_USER_DISABLED = 17;
			const ERROR_PAYPAL = 18;

			let e = error.error;
			let message = e.errors;
			if (e.response == ERROR_USER_DISABLED) {
				message = "Utente Disabilitato";
			} else if (e.response == ERROR_ACTION_PARAM_NOT_VALID) {
				//message = "Inserire tutti campi previsti";
			} else if (e.response == ERROR_DELETE_DB_REFERENCE) {
				message = "Impossibile eliminare perché referenziato";
			} else if (e.response == ERROR_PASSWORD_DONT_MACTH) {
				message = "Le password non coincidono";
			} else if (e.response == ERROR_DUPLICATE_ENTRY) {
				message = "Elemento già presente";
			} else if (e.response == ERROR_PAYPAL) {
				message = "Errore nel pagamento. Contattare il supporto";
			}
			else if (e.response == ERROR_INVALID_SESSION) {
				TokenStorage.clear();
				document.location.href = environment.BASE_PATH;
			}
			e.errors = message;



			console.error(
				`Backend returned code ${error.status}, `,
				`body was:`, error.error);
			return throwError(
				e);
		}
	}
}
