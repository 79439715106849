import { ConfigModel } from '../core/interfaces/config';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			'/': {
				page: {
					title: 'Dashboard',
					desc: 'Latest updates and statistic charts'
				}
			},

			'dashboard': {

				carico: {
					page: {
						title: 'Gestione Carichi', desc: 'Carichi'
					}
				},
				fornitori: {
					page: {
						title: 'Gestione Fornitori', desc: 'Fornitori'
					}
				},

				survey: {
					gestione: {
						page: { title: 'Gestione survey', desc: 'survey' }
					},


				},
				asl: {
					page: {
						title: 'Gestione ASL', desc: 'ASL'
					}
				},
				hospitals: {
					page: {
						title: 'Gestione Ospedali', desc: 'Ospedali'
					}
				}


			},
			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }
			}
		};
	}
}
