// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: []

			},
			aside: {
				self: {},
				items: [
					// {
					// 	title: 'Dashboard',
					// 	desc: 'Some description goes here',
					// 	root: true,
					// 	icon: 'flaticon-line-graph',
					// 	page: '/',
					// 	badge: { type: 'm-badge--danger', value: '2' },
					// 	translate: 'MENU.DASHBOARD'
					// },

					{ section: 'Utenze' },
					{
						title: 'Utenti',
						root: true,
						bullet: 'dot',
						icon: 	'flaticon-user',
						submenu: [
							{
								title: 'Admin',
								page: '/kpi-lbs/utenti/admin'
							},
							{
								title: 'Personale',
								page: '/kpi-lbs/utenti/app'
							},

						]
					},



					{
						title: 'Carico',
						root: true,
						bullet: 'dot',
						icon: 'flaticon-comment',
						page: '/dashboard/carico',

					},
					{
						title: 'Fornitori',
						root: true,
						bullet: 'dot',
						icon: 'flaticon-comment',
						page: '/dashboard/fornitori',

					}

				]
			}
		};
	}
}
