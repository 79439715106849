// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	//URL_SERVER:'http://62.170.10.171/rest/Api/src/admin/',
	//URL_SERVER: 'http://192.168.1.151/rest/Api/src/admin/',
	//URL_SERVER:'http://localhost/caseificio-molise-server/Api/src/admin/',
	URL_SERVER:'https://barone.devappers.com/rest/Api/src/admin/',
	BASE_PATH: '/'
};
