import { environment } from "../../environments/environment";

export class Utils {


	static log(message?: any, ...optionalParams: any[]) {
		if (environment.production == false) {
			console.log(message, optionalParams);
		}
	}

	static replace(arr, key, obj) {

		// Utils.log(arr, "try to delete " + value + " => " + key + "!");

		for (var i = 0; i < arr.length; i++) {
			if (arr[i][key] == obj[key]) {
				arr[i] = obj;
			}
		}

		return arr;
	}

	static remove(arr, key, value) {

		// Utils.log(arr, "try to delete " + value + " => " + key + "!");

		for (var i = 0; i < arr.length; i++) {
			if (arr[i][key] == value) {
				arr.splice(i, 1);
			}
		}

		return arr;
	}


	static find(arr, key, value): any {

		for (var i = 0; i < arr.length; i++) {
			
			if (arr[i][key] == value) {

				return arr[i];
			}
		}

		return null;

	}

	static findDual(arr, key, value, key2): any {

		for (var i = 0; i < arr.length; i++) {
			
			if (arr[i][key][key2] == value) {

				return arr[i];
			}
		}

		return null;

	}

	static clone(c: any, empty: any): any {

		for (let prop in c) {
			empty[prop] = c[prop];
		}
		return empty;
	}


	static assign(orgObj) {
		return Object.assign({}, orgObj);
	}

	static pushIn(index, item) {
		item.splice(index, 0, item);
	};


	static getBase64(file, success, error) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {

			var data64 = reader.result;

			success(data64)
		};
		reader.onerror = function (e) {
			Utils.log('Error: ', e);
			error(e)
		};
	}

	static b64toBlob(b64Data, contentType, sliceSize) {
		contentType = contentType || '';
		sliceSize = sliceSize || 512;

		var byteCharacters = atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	static openDownloadFile(base64, blob, mime, filename, open) {
		if (blob == null)
			blob = Utils.b64toBlob(base64, mime, null);

		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, filename);
			return;
		}

		var blobUrl = URL.createObjectURL(blob);
		if ((mime != null && mime != "" && open) && (mime == "application/pdf" || mime.indexOf("audio") != -1 || mime.indexOf("video") != -1)) {

			window.open(blobUrl);
		}
		else {
			let a = document.createElement("a");
			if (base64 == null) {
				a.href = blobUrl;
			} else {
				a.href = "data:" + mime + ";base64," + base64;

			}
			a.download = filename;
			document.body.appendChild(a);
			// a.click();
			// a.remove();
			a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))


		}
	}

	static isEmpty(vari: any): boolean {
		if (vari == undefined || vari == null || vari == "") {
			return true;
		}
		else return false;
	}



}
