import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GenericService } from "../../../../../devappers/services/generic-service";
import { ConfigModel } from '../../../../../core/interfaces/config';

@Injectable({
  providedIn: 'root'
})
export class DynamicMenuService extends GenericService<any> {

  constructor(http: HttpClient) {
    super(http, "MenuAF");
  }
}