import { DynamicMenuService } from './../../../content/pages/caseificio-molise/_core/services/dynamic-menu.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuConfigService } from '../menu-config.service';
import { ClassInitService } from '../class-init.service';
import * as objectPath from 'object-path';
import { LayoutConfigService } from "../layout-config.service";
import { Utils } from '../../../devappers/utils';
import { catchError, take } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";

@Injectable()
export class MenuAsideService {
	classes: string;
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject([]);

	isDropdown: number = 0;
	dropdownTimeout: number;
	isScrollable: number = 0;
	menuItems = [];

	cliente: BehaviorSubject<any> = new BehaviorSubject({});

	constructor(
		private menuConfigService: MenuConfigService,
		private classInitService: ClassInitService,
		private layoutConfigService: LayoutConfigService,
		private dmService: DynamicMenuService,
		private titleService: Title
	) {



		// get menu list
		this.menuConfigService.onMenuUpdated$.subscribe(model => {

			// this.dmService.getAll().subscribe(res => {

			// 	console.log("RES", res);

			// 	let val = res[0];
			// 	this.titleService.setTitle(val['title']);

			// 	//val = res[1];


			// 	console.log("VAL", val);



			// })

			this.dmService.getAll().pipe(take(1)).subscribe((val) => {				
			//this.dmService.getAll().subscribe((val) => {

				this.menuItems = val;
				model.config.aside.items = this.menuItems;

				this.cliente.next(val[0]['cliente']);
				this.titleService.setTitle(this.cliente.value.nome);

				setTimeout(() =>
					this.menuList$.next(objectPath.get(model.config, 'aside.items'))
				);


			}, err => {

				alert("Errore: "+err.errors);
			});

		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(config => {

			Utils.log(" ***** on layout ******");

			if (objectPath.get(config, 'config.aside.left.fixed')) {
				this.isScrollable = 1;
				this.isDropdown = 0;
			}

			if (!objectPath.get(config, 'config.aside.left.fixed') && !objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.accordion')) {
				this.isScrollable = 0;
				this.isDropdown = 1;
				this.dropdownTimeout = objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.dropdown.hover_timeout');
			}
		});


	}
}
